<template>
  <layout name="Default">
    <page-section hide-title>
      <div class="tw-py-4 tw-px-4 tw-flex tw-flex-col tw-gap-6">
        <div class="tw-w-[30%]">
          <BasicSelect
            v-model="selectedGroup"
            :values="groups"
            key-prop="value"
            label-prop="label"
            value-prop="value"
          />
        </div>

        <Events :loading="loading" :events="events" />

        <EntitiesTable
          :loading="loading"
          :entities="entities"
          :sorting="sorting"
          @sorting:update="handleSortingUpdate"
        />
      </div>
    </page-section>
  </layout>
</template>

<script>
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'

import Events from '@/pages/trends/partials/Events.vue'
import EntitiesTable from '@/pages/trends/partials/EntitiesTable.vue'

export default {
  components: {
    BasicSelect,
    Events,
    EntitiesTable
  },

  data () {
    return {
      loading: false,
      groups: [],
      entities: [],
      events: [],
      topics: [],
      selectedGroup: 'entertainers',
      sorting: {
        column: 'latest',
        direction: 'desc'
      }
    }
  },

  watch: {
    async selectedGroup () {
      await this.fetchEntities()
    }
  },

  async mounted () {
    await this.fetchEntities()
  },

  methods: {
    async fetchEntities () {
      this.loading = true

      this.$api.get(`/trends/entities?sortColumn=${this.sorting.column}&sortDirection=${this.sorting.direction}&group=${this.selectedGroup}`)
        .then(response => {
          this.groups = response.data.groups
          this.entities = response.data.entities
          this.events = response.data.events
          this.topics = response.data.events
          this.loading = false
        })
    },

    handleSortingUpdate (sorting) {
      this.sorting.column = sorting.column
      this.sorting.direction = sorting.direction

      this.fetchEntities()
    }
  }
}
</script>

<style lang="scss">
@import './trends.css';
</style>
